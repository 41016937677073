<template>
  <div id="advantages">
    <div class="nav">
      <ul class="nav-list">
        <li class="nav-item nav-link--active">
          <nuxt-link
            :class="
              $route.query.advType == 'buyer' || !$route.query.advType
                ? 'nav-link nav-link--active'
                : 'nav-link'
            "
            :to="{ query: { advType: 'buyer' }, hash: '#advantages' }">
            Для покупателей
          </nuxt-link>
        </li>
        <li class="nav-item active">
          <nuxt-link
            :class="
              $route.query.advType == 'seller'
                ? 'nav-link nav-link--active'
                : 'nav-link'
            "
            :to="{ query: { advType: 'seller' }, hash: '#advantages' }">
            Для продавцов
          </nuxt-link>
        </li>
      </ul>
    </div>
    <div class="container advantages">
      <div class="row">
        <div
          v-for="(item, index) in borderItems"
          :key="'advantage-1-' + index"
          class="advantage col-6">
          <advantage
            border
            img-align="right"
            img-full-right
            title-big
            :title="item.title"
            :content="item.content"
            :img-src="item.imgSrc"/>
        </div>
        <div
          v-for="(item, index) in borderItemsCol3"
          :key="'advantage-2-' + index"
          class="advantage col-4">
          <advantage
            border
            img-align="center"
            :title="item.title"
            :content="item.content"
            :img-style="item.imgStyle"
            :img-src="item.imgSrc"/>
        </div>
        <div
          v-for="(item, index) in filterByType(itemsCol3Dark)"
          :key="'advantage-3-' + index"
          class="advantage col-4">
          <advantage
            img-align="right"
            background-color="#2D376F"
            :title="item.title"
            :content="item.content"
            :img-src="item.imgSrc"/>
        </div>
        <div
          v-for="(item, index) in filterByType(itemsCol3Light)"
          :key="'advantage-4-' + index"
          class="advantage col-4">
          <advantage
            img-align="right"
            background-color="linear-gradient(105deg, rgba(49,79,142, 0.7) 8.7%, rgba(49,79,142, 1) 94.23%)"
            :title="item.title"
            :content="item.content"
            :img-src="item.imgSrc"/>
        </div>
        <div
          v-for="(item, index) in filterByType(shadowItemsCol3)"
          :key="'advantage-5-' + index"
          class="advantage col-4">
          <advantage
            box-shadow
            img-top
            img-align="center"
            :title="item.title"
            :content="item.content"
            :img-src="item.imgSrc"/>
        </div>
        <div
          v-for="(item, index) in filterByType(shadowItemsCol3Separator)"
          :key="'advantage-6-' + index"
          class="advantage col-6">
          <advantage
            box-shadow
            img-align="right"
            img-full-right
            title-separator
            :title="item.title"
            :content="item.content"
            :img-src="item.imgSrc"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import advantage from "~/components/advantages/advantage";
export default {
  components: {
    advantage,
  },
  data() {
    return {
      type: null,
      backendUrl: process.env.backendUrl,
      itemsCol3Dark: [],
      itemsCol3Light: [],
      shadowItemsCol3: [],
      shadowItemsCol3Separator: [],
    };
  },
  computed: {
    borderItems() {
      let items = [
        {
          type: "buyer",
          title: "ЗАЩИТА СДЕЛОК",
          content:
            "GOBUFFY SHIELD - система защиты покупателей от недобросовестных продавцов. Все денежные средства проходят депонирование и поступают исполнителю только после задержки",
          imgSrc: "/pictures/advantages/gb-shield.svg",
        },
        {
          type: "buyer",
          title: "НИЗКИЕ ЦЕНЫ",
          content:
            "Низкая комиссия - залог низких цен. Мы привлекаем лучших продавцов для обеспечения конкурентноспособной среды и снижения цен на любые услуги",
          imgSrc: "/pictures/advantages/graphic.svg",
        },
        {
          type: "seller",
          title: "КОМИССИЯ 0%",
          content: "Торгуйте первые 90 дней без комиссии на нашей площадке",
          imgSrc: "/pictures/advantages/zero-comission.svg",
        },
        {
          type: "seller",
          title: "БЫСТРЫЕ ВЫПЛАТЫ",
          content:
            "Для наших продавцов - быстрое проведение выплат на банковские карты, QIWI, Яндекс.Деньги, WebMoney и другие реквизиты.",
          imgSrc: "/pictures/advantages/fast-payouts.svg",
        },
      ];
      return this.filterByType(items);
    },
    borderItemsCol3() {
      let items = [
        {
          type: "buyer",
          title: "ВОЗВРАТ СРЕДСТВ",
          content:
            "Сделка пошла не по плану?! Не проблема, все денежные средства находятся под нашей защитой и мы без труда вернем их на баланс.",
          imgSrc: "/pictures/advantages/money.svg",
        },
        {
          type: "buyer",
          title: "ПОДДЕРЖКА ПОКУПАТЕЛЕЙ",
          content:
            "Есть вопросы? Наши агенты поддержки всегда на связи с 9:00 до 21:00 МСК",
          imgSrc: "/pictures/advantages/support.svg",
        },
        {
          type: "buyer",
          title: "ВЕРИФИКАЦИЯ ПРОДАВЦОВ",
          content:
            "Каждый продавец на нашем маркетплейсе прошел идентификацию, а все предложения - реальные",
          imgSrc: "/pictures/advantages/verification.svg",
        },
        {
          type: "seller",
          title: "ВЫДЕЛЯЙСЯ",
          content:
            "Гибкая система отзывов и персонификации для продавцов для привлечения большего количества покупателей",
          imgSrc: "/pictures/advantages/spotlight.svg",
        },
        {
          type: "seller",
          title: "БЕЗ НЕОЖИДАННЫХ БЛОКИРОВОК",
          content:
            "Баланс заморозили, а аккаунт заблокирован? Только не у нас! Четкая и понятная система предупреждений для продавцов",
          imgSrc: "/pictures/advantages/jail.svg",
        },
        {
          type: "seller",
          title: "УДОБНЫЙ МЕССЕНДЖЕР",
          content:
            "Современный мессенджер для общения с покупателями прямо на сайте. торгуйте комфортно 24/7 вместе с нами",
          imgSrc: "/pictures/advantages/messenger.svg",
        },
      ];
      return this.filterByType(items);
    },
  },
  watch: {
    "$route.query.advType": {
      async handler(type) {
        if (!type) {
          this.type = 'buyer';
        }
        else {
          this.type = type;
        }
      },
      deep: false,
      immediate: true,
    },
  },
  methods: {
    filterByType(advantages) {
      return advantages.filter((item) => {
        return item.type && item.type === this.type;
      });
    },
  },
  scrollBehavior() {
    return {
      selector: "#advantages",
    };
  },
};
</script>

<style lang="scss" scoped>
.advantages {
  margin-top: 32px;
}
.advantage {
  margin-bottom: 20px;
  padding: 0 10px !important;
}
.nav {
  padding: 0 16px;
  font-size: 14px;
  border-bottom: 2px solid #eff1f4;
  margin-bottom: 32px;
  margin-top: 64px;
  text-align: center;
}
.nav-list {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  list-style: none;
  width: 100%;
}
.nav-item {
  margin-right: 36px;
}
.nav-link {
  position: relative;
  display: inline-block;
  padding: 0 27px 17px 27px;
  color: #a8aaaf;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;

  &:after {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    right: 0;
    height: 2px;
    background: #098aff;
    opacity: 0;
    transition: $link-transition;
  }
  &.nav-link--active {
    color: $blue;
    &:after {
      opacity: 1;
    }
  }
  &:not([href]):not([tabindex]) {
    color: #888888;
  }
}
</style>
