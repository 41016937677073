<template>
  <div
    :style="'background:' + backgroundColor"
    :class="['box', {'box-shadow': boxShadow, 'border': border, 'text-white': backgroundColor, 'reverse': imgTop, 'img-full-right': imgFullRight, 'title-big': titleBig }]">
    <div class="box-body">
      <h3 :class="titleSeparator ? 'title-separator': ''">
        {{ title }}
      </h3>
      {{ content }}
    </div>
    <div
      :style="imgStyle"
      :class="['box-image', imgAlign]">
      <img
        :src="imgSrc"
        alt="">
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      content: {
        type: String,
        default: ''
      },
      border: {
        type: Boolean,
        default: false
      },
      boxShadow: {
        type: Boolean,
        default: false
      },
      backgroundColor: {
        type: String,
        default: ''
      },
      imgSrc: {
        type: String,
        default: ''
      },
      imgAlign: {
        type: String,
        default: ''
      },
      imgStyle: {
        type: String,
        default: ''
      },
      titleSeparator: {
        type: Boolean,
        default: false
      },
      imgTop: {
        type: Boolean,
        default: false
      },
      imgFullRight: {
        type: Boolean,
        default: false
      },
      titleBig: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        searchValue: ''
      }
    },
  }
</script>

<style lang="scss" scoped>
  .box{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 24px 30px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7;
    color: #6E7279;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    justify-content: space-between;
    &.reverse{
      flex-direction: column-reverse;
      .box-image{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
      }
      img{
        max-width: 170px;
        max-height: 100%;
      }
    }
    &.border{
      border: 1px solid #DADADA;
    }
    &.box-shadow{
      box-shadow: 0 6px 22px rgba(0, 0, 0, 0.1);
    }
    &.text-white{
      h3{
        color: #ffffff;
      }
    }
    h3{
      position: relative;
      font-size: 20px;
      color: #2D303A;
      &.title-separator{
        margin-bottom: 26px;
        &:after{
          position: absolute;
          top: 100%;
          left: 0;
          margin-top: 13px;
          content: "";
          background: #FF8900;
          width: 50px;
          height: 2px;
        }
      }
    }
    &.title-big{
      h3{
        font-size: 24px;
      }
    }
  }
  .box-image{
    width: 100%;
    &.center{
      text-align: center;
    }
    &.right{
      text-align: right;
      img{
        width: 121px;
      }
    }
    img{
      max-width: 100%;
      height: 190px;

    }
  }
  .img-full-right{
    flex-direction: row;
    flex-wrap: wrap;
    .box-body{
      width: calc(100% - 200px);
    }
    .box-image{
      width: 200px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      img{
        width: 100%;
      }
    }
  }
</style>