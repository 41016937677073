<template>
  <div
    class="search main">
    <input
      v-model="searchValue"
      :placeholder="placeholder"
      type="text"
      class="input"
      @keyup.enter="submitSearch">
    <button
      class="submit"
      @click="submitSearch">
      <svgicon
        name="search"
        width="18"
        height="18"
        class="search-icon"/>
    </button>
  </div>
</template>

<script>
  import '../../icons/search';
  import {mapState} from 'vuex';

  export default {
    props: {
      placeholder: {
        type: String,
        default: 'Поиск'
      }
    },
    data() {
      return {
        searchValue: ''
      }
    },
    methods: {
      submitSearch: function(){
        this.$router.push({
          path: '/search?q=' + this.searchValue
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search{
    position: relative;
    margin-right: rem(16px);
    width: rem(336px);
    &.main{
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      .input{
        height: 48px;
        font-size: 16px;
      }
    }
  }
  .input{
    width: 100%;
    height: rem(40px);
    padding-left: rem(15px);
    border: none;
    border-radius: rem(4px);
    background: rgba(#D8D8D8, .1);
    font-size: rem(13px);
    color: #ffffff;
    &::placeholder{
      color: rgba(#ffffff, .5);
    }
    &:hover, &:active, &:focus{
      //outline: 1px solid rgba(#ffffff, .5);
    }
  }
  .submit{
    @include reset-button;
    position: absolute;
    top: 50%;
    right: rem(14px);
    margin-top: rem(-9px);
    width: rem(18px);
    height: rem(18px);
    line-height: 1;
  }
  .search-icon{
    fill: rgba(#ffffff, .5);
  }
</style>